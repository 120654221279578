<template>
    <div class="manage_project wrapper">
        <proTitle></proTitle>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="研训空间" name="first">
                <el-form :inline="true" class="demo-form-inline flex-align-between">
                    <div class="formInline-left">
                    </div>
                    <div class="formInline-right">
                        <i class="el-icon-circle-plus-outline"></i>
                        <span class="csp" @click="$router.push(`/space/spaceCreate?breadNumber=4`)">创建论坛</span>
                    </div>
                </el-form>
                <div class="mid-bot">
                    <el-table :data="tableData">
                        <el-table-column type="index" label="序号" align="center"></el-table-column>
                        <el-table-column prop="id" label="论坛编号" align="center"></el-table-column>
                        <el-table-column prop="name" label="论坛名称" align="center"></el-table-column>
                        <el-table-column prop="createTime" label="时间" align="center"></el-table-column>
                        <el-table-column prop="type" label="操作" fixed="right" width="180px" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="isdelete(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination v-show="total > 0" :total="total" :page.sync="page" :limit.sync="limit"
                        :pageSizes="[5, 10, 15]" @pagination="handlePageChange">
                    </pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="数据统计" name="second">
                <el-empty description="暂无统计"></el-empty>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
  
<script>
import QRCode from 'qrcode'
import {
    mapState
} from "vuex";
export default {
    data() {
        return {
            statusList: {
                0: '未发布',
                1: '已发布',
                2: '已暂停',
                3: '已删除',
                4: '彻底删除',
                5: '被审核'
            },
            tableData: [],
            page: 1,
            limit: 10,
            total: 0,
            activeName: 'first'
        };
    },
    computed: {
        ...mapState({
            currentProject: state => state.user.currentProject,
        })
    },
    methods: {
        // 翻页
        handlePageChange(data) {
            this.page = data.pageIndex
            this.limit = data.pageSize
            this.listForAdmin()
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        async listForAdmin() {
            let data = {
                page: this.page,
                limit: this.limit,
                projectId: this.currentProject.id
            }
            let resData = await this.$Api.space.listForAdmin(data)
            console.log('K吧列表:', resData);
            this.tableData = resData.data.records;
            this.total = resData.data.total
        },
        // 删除提示
        isdelete(id) {
            this.$confirm("确定删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let data = {
                    id
                }
                this.$Api.space.delete(data)
                    .then(res => {
                        // console.log('删除成功:',res);
                        this.listForAdmin()
                    })
                    .catch(err => {
                        console.log('删除失败:', err)
                    })
            })
                .catch((err) => {
                    console.log('err:', err)
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
    },
    mounted() {
        this.listForAdmin()
    }
};
</script>
  
<style lang="less" scoped>
@import "../less/table.less";

.mid-bot {
    margin-top: 20px;
}

.qrBox {
    .qrcodeItem {
        .name {
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }

        .url {
            width: 250px;
            display: block;
            margin: 10px 0;
        }
    }
}

/deep/ .el-dialog__body {
    padding: 0 20px 50px;
}
</style>